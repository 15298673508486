//import Notiflix from 'notiflix';
import { Notify } from 'notiflix/build/notiflix-notify-aio'
import emailSpellChecker from '@zootools/email-spell-checker'
import paywall from '../util/Paywall'
import('@mux/mux-player').then(() => {
  onMuxPlayerLoaded()
})
//import MuxPlayer from '@mux/mux-player'

const DEV = process.env.NODE_ENV === 'development'

import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'

function onMuxPlayerLoaded() {
  let live_player = document.getElementById('live-video-player')
  if (live_player && live_player.dataset.muxAutoplay === 'true') {
    live_player.play()
  }
  // const muxPlayer = document.querySelector('mux-player')
  // if (!muxPlayer) return
  // muxPlayer.addEventListener('error', (event) => {
  //   // CODE 412: This Live video is not ready yet
  //   //console.log(event)
  //   if (
  //     event.detail.data.error.message &&
  //     event.detail.data.error.message.includes('status 412')
  //   ) {
  //     console.log('LIVE BROADCAST IS NOT READY YET')
  //     muxPlayer.style.setProperty('--dialog', 'none')
  //     muxPlayer.style.setProperty('--play-button', 'none')
  //     setTimeout(() => {
  //       muxPlayer.load()
  //     }, 60000)
  //   }
  // })
}

function activateCopyToClipboard() {
  // Event delegation to handle future elements
  document.body.addEventListener('click', (e) => {
    if (e.target.matches('.copy-url-at')) {
      handleCopyToClipboard(e)
    }
  })
}

function handleCopyToClipboard(e) {
  // COPY URL TO CLIPBOARD

  let targetUrl = e.target.dataset.url
  var tempInput = document.createElement('input')
  tempInput.style = 'position: absolute; left: -1000px; top: -1000px'
  tempInput.value = targetUrl
  document.body.appendChild(tempInput)
  tempInput.select()
  tempInput.setSelectionRange(0, 99999)
  document.execCommand('copy')
  document.body.removeChild(tempInput)
  //console.log(targetUrl)
  //console.log(e.target)
  Notify.success(e.target.dataset.successMessage)
}

function activateEmailSpellChecker() {
  // Event delegation to handle future elements
  document.body.addEventListener('focusout', (e) => {
    if (e.target.matches('input[type="email"]')) {
      handleEmailSpellChecker(e)
    }
  })
}
function handleEmailSpellChecker(e) {
  // CHECK EMAIL SPELLING
  let email = e.target.value
  let input = e.target
  console.log(email)
  const suggestedEmail = emailSpellChecker.run({
    email,
  })
  if (suggestedEmail) {
    // TODO: Handle the suggested email
    // Example: Show a modal to the user with the suggested email
    console.log('suggestedEmail', suggestedEmail)
    // https://app.zootools.co/signup

    // const suggestionDiv = document.createElement('div')
    // suggestionDiv.className = 'email-suggestion'
    // suggestionDiv.innerHTML = `Did you mean <strong>${suggestedEmail}</strong>?`
    // input.parentNode.appendChild(suggestionDiv)

    /// check https://app.zootools.co/signup

    // Notify.info(
    //   `Did you mean <strong>${suggestedEmail}</strong> instead of <strong>${email}</strong>?`
    // )
  }
}

// function activateCopyToClipboard() {
//   // COPY URL TO CLIPBOARD
//   let copyButtons = document.querySelectorAll('.copy-url-at')
//   copyButtons.forEach((button) => {
//     button.addEventListener('click', (e) => {
//       let targetUrl = e.currentTarget.dataset.url
//       var tempInput = document.createElement('input')
//       tempInput.style = 'position: absolute; left: -1000px; top: -1000px'
//       tempInput.value = targetUrl
//       document.body.appendChild(tempInput)
//       tempInput.select()
//       tempInput.setSelectionRange(0, 99999)
//       document.execCommand('copy')
//       document.body.removeChild(tempInput)
//       //console.log(targetUrl)
//       Notify.success(e.currentTarget.dataset.successMessage)
//     })
//   })
// }

export default {
  init() {
    let notify_position = 'right-top'
    //check if mobile
    if (document.getElementById('bkp-lg')) {
      if (document.getElementById('bkp-lg').offsetParent === null) {
        notify_position = 'right-bottom'
      }
    }

    Notify.init({
      position: notify_position,
      distance: '30px',
      timeout: 3000,
      showOnlyTheLastOne: true,
      clickToClose: true,
      pauseOnHover: true,
      fontFamily: 'Inherit',
      plainText: false,
      messageMaxLength: 600,
      success: {
        background: '#ffffff',
        textColor: '#1c1b1c',
        childClassName: 'notiflix-notify-success',
      },

      failure: {
        background: '#ffffff',
        textColor: '#1c1b1c',
        childClassName: 'notiflix-notify-failure',
      },

      warning: {
        background: '#ffffff',
        textColor: '#1c1b1c',
        childClassName: 'notiflix-notify-warning',
      },

      info: {
        background: '#ffffff',
        textColor: '#1c1b1c',
        childClassName: 'notiflix-notify-info',
      },
      // ...
    })

    // activateEmailSpellChecker()
    // JavaScript to be fired on all pages
    //console.log('init common');
    paywall.init()

    // Remove no-js
    document.documentElement.classList.remove('no-js')
    document.documentElement.classList.add('js')

    // Test touch
    if (!('ontouchstart' in document.documentElement)) {
      document.documentElement.classList.add('no-touch')
    } else {
      document.documentElement.classList.add('touch')
    }

    //live video language selection
    document.querySelectorAll('.panel-toggle').forEach((item) => {
      item.addEventListener('click', (event) => {
        item.classList.toggle('open')
      })
    })
    document.querySelectorAll('.panel-option').forEach((item) => {
      item.addEventListener('click', (event) => {
        if (item.dataset.stream && item.dataset.playerId) {
          const liveMuxPlayer = document.getElementById(item.dataset.playerId)
          if (liveMuxPlayer) {
            liveMuxPlayer.setAttribute('playback-id', item.dataset.stream)
            liveMuxPlayer.load()
            liveMuxPlayer.setAttribute('autoplay', 'true')
            liveMuxPlayer.removeAttribute('muted')
          }
        }
        const closestPanelToggle =
          item.parentElement.parentElement.querySelector('.panel-toggle')
        if (closestPanelToggle) {
          closestPanelToggle.querySelector('.current_selection').innerHTML =
            item.innerHTML
          closestPanelToggle.classList.remove('open')
        }
      })
    })

    //accordion
    document.querySelectorAll('.js-accordion').forEach((item) => {
      item.addEventListener('click', (event) => {
        let tg = item.getAttribute('data-target')
        if (tg) {
          item.classList.toggle('open')
          let panel = document.querySelector(tg)
          if (panel.style.maxHeight) {
            panel.style.maxHeight = null
          } else {
            panel.style.maxHeight = panel.scrollHeight + 'px'
          }
        }
      })
    })

    /*Toggle class*/
    document.querySelectorAll('.js-toggle-class').forEach((item) => {
      item.addEventListener('click', (event) => {
        let tg = item.getAttribute('data-target')
        let className = item.getAttribute('data-classname')
        if (tg && className) {
          item.classList.toggle('clicked')
          document.querySelectorAll(tg).forEach((target) => {
            target.classList.toggle(className)
          })
          if (item.classList.contains('clicked')) {
            document
              .querySelectorAll('[data-target="' + tg + '"]')
              .forEach((i) => {
                i.classList.remove('clicked')
              })
            item.classList.add('clicked')
          } else {
            document
              .querySelectorAll('[data-target="' + tg + '"]')
              .forEach((item) => {
                item.classList.remove('clicked')
              })
          }
        }
      })
    })

    /* Add active class when in viewport */
    let wh = window.innerHeight
    let tol = wh * 0.5 //  scroll tolerance - eg. 77% from page top
    const scrollTrigger = (elem, pix, cls) => {
      if (document.getElementsByClassName(elem).length > 0) {
        let elems = document.getElementsByClassName(elem)
        const check = () => {
          for (let i = 0; i < elems.length; i++) {
            let off = elems[i].getBoundingClientRect().top
            if (off <= pix) {
              elems[i].classList.add(cls)
            } else {
              elems[i].classList.remove(cls)
            }
          }
          requestAnimationFrame(check)
        }
        requestAnimationFrame(check)
      }
    }
    scrollTrigger('js-highlight-onscroll', tol, 'active')

    /* SET REDIRECT COOKIE */
    const setCookie = function (cname, cvalue, exdays) {
      const d = new Date()
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
      let expires = 'expires=' + d.toUTCString()
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
    }

    document.body.addEventListener('click', function (e) {
      if (e.target.matches('.redirect_btn')) {
        setCookie('gc_redirect', window.location.href, 1)
      }
    })

    /* EXPAND SHARE BUTTON */
    document.querySelectorAll('.btn-share-expand').forEach((item) => {
      item.addEventListener('click', (event) => {
        window.shareIsOpen = true
        event.currentTarget.parentNode.classList.toggle('expanded')
      })
    })

    //SWIPER
    document
      .querySelectorAll('.custom_swiper')
      .forEach((swiperContainer, index) => {
        let swiperID = swiperContainer.getAttribute('id'),
          slidesPerView =
            swiperContainer.getAttribute('data-slidePerView') || 3,
          spaceBetween = swiperContainer.getAttribute('data-spaceBetween') || 0,
          slidesTablet = slidesPerView > 1 ? 2 : 1,
          spaceTablet = slidesPerView > 1 ? 20 : 0
        console.log(slidesTablet)
        new Swiper('#' + swiperID, {
          modules: [Navigation, Pagination],
          slidesPerView: slidesPerView > 1 ? 'auto' : 1,
          spaceBetween: 20,
          breakpoints: {
            768: {
              slidesPerView: slidesTablet,
              spaceBetween: spaceTablet,
            },
            1024: {
              slidesPerView: slidesPerView,
              spaceBetween: spaceBetween,
            },
          },
          navigation: {
            prevEl: '#' + swiperID + 'Prev',
            nextEl: '#' + swiperID + 'Next',
          },
          pagination: {
            el: '#' + swiperID + 'Pagination',
          },
        })
      })

    //VIDEO PLAYER PREVIEW

    document.querySelectorAll('.video_feat, .video-thumb').forEach((item) => {
      item.addEventListener('mouseenter', (event) => {
        item.classList.add('active')
      })
      item.addEventListener('mouseleave', (event) => {
        item.classList.remove('active')
      })

      // if (!item.dataset.videoPreview) return
      // item.addEventListener('mouseenter', (event) => {
      //   if (
      //     item.parentElement.parentElement.classList.contains(
      //       'swiper-slide-active'
      //     ) ||
      //     item.classList.contains('video-thumb')
      //   ) {
      //     let video = document.createElement('video')
      //     //video.src = item.getAttribute('data-video-src');
      //     video.src = item.dataset.videoPreview //  '//localhost:4000/fr/wp-content/themes/lgc/assets/media/SommetGrandContinent.webm'
      //     video.style.opacity = '0'
      //     video.style.transition = 'opacity 1.5s'
      //     video.autoplay = true
      //     video.muted = true
      //     video.loop = true
      //     video.classList.add(
      //       'video-preview',
      //       'absolute',
      //       'left-0',
      //       'top-0',
      //       'w-full',
      //       'h-full',
      //       'object-cover',
      //       'z-1'
      //     )
      //     item.prepend(video)
      //     requestAnimationFrame(() => {
      //       video.style.opacity = '1'
      //     })
      //   }
      // })
      // item.addEventListener('mouseleave', (event) => {
      //   let video = item.querySelector('.video-preview')
      //   if (video) {
      //     video.style.transition = 'opacity 0.5s'
      //     video.style.opacity = '0'
      //     video.addEventListener(
      //       'transitionend',
      //       () => {
      //         video.pause()
      //         item.removeChild(video)
      //       },
      //       { once: true }
      //     )
      //   }
      // })
    })

    // Add click event listener to the button
    document.querySelectorAll('.playVideoPreview').forEach((item) => {
      item.addEventListener('click', function (e) {
        e.target.parentElement.classList.add('started')
        setTimeout(() => {
          this.classList.add('hidden')
        }, 1000)
        let thisMuxPlayer = e.target.parentElement.querySelector('mux-player')
        thisMuxPlayer.play()
      })
    })

    //copy to clipboard
    activateCopyToClipboard()

    //ADD SCROLL CLASS TO BODY
    window.addEventListener('scroll', () => {
      if (window.scrollY > 10) {
        document.body.classList.add('scrolled')
      } else {
        document.body.classList.remove('scrolled')
      }
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    //console.log('finalize common');
  },
}
