const DEV = process.env.NODE_ENV === 'development';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import PhotoSwipeDynamicCaption from '../libs/photoswipe-caption';

export default {
	init() {
		//alert('TEX STAFFX INNIX')
		document.querySelectorAll('.author-tabs-btn').forEach((button) => {
			button.addEventListener('click', function (e) {
				e.preventDefault();
				// Remove active class from previously active button
				document
					.querySelector('.author-tabs-btn.active')
					?.classList.remove('active');
				// Add active class to the clicked button
				this.classList.add('active');

				// Remove active class from previously active content
				document
					.querySelector('.author-tabs-content.active')
					?.classList.remove('active');
				// Add active class to the corresponding content
				const target = this.getAttribute('data-target');
				document
					.getElementById(`content-${target}`)
					.classList.add('active');
			});
		});

		const closeSvgString =
			'<svg width="24" height="24" viewBox="0 0 24 24" fill="#000000" xmlns="http://www.w3.org/2000/svg"><path d="M 23.142578 0 C 22.923216 0 22.704509 0.084585725 22.537109 0.25195312 L 14.857422 7.9296875 L 14.857422 3 C 14.857422 2.52661 14.4734 2.1425781 14 2.1425781 C 13.5266 2.1425781 13.142578 2.52661 13.142578 3 L 13.142578 9.8574219 C 13.142578 10.330772 13.5266 10.714844 14 10.714844 L 20.857422 10.714844 C 21.330822 10.714844 21.714844 10.330772 21.714844 9.8574219 C 21.714844 9.3840319 21.330822 9 20.857422 9 L 16.212891 9 L 23.748047 1.4628906 C 24.082847 1.1281606 24.082847 0.58668813 23.748047 0.25195312 C 23.580697 0.084585725 23.361941 0 23.142578 0 z M 3 13.142578 C 2.52661 13.142578 2.1425781 13.5266 2.1425781 14 C 2.1425781 14.4734 2.52661 14.857422 3 14.857422 L 7.9296875 14.857422 L 0.25195312 22.537109 C -0.082781675 22.871809 -0.082781675 23.413247 0.25195312 23.748047 C 0.58668813 24.082747 1.1281606 24.082747 1.4628906 23.748047 L 9 16.212891 L 9 20.857422 C 9 21.330722 9.3840419 21.714844 9.8574219 21.714844 C 10.330802 21.714844 10.714844 21.330722 10.714844 20.857422 L 10.714844 14 C 10.714844 13.5266 10.330802 13.142578 9.8574219 13.142578 L 3 13.142578 z " /></svg>';

		const arrowPrevString =
			'<svg height="16" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 17L4 9L12 0.999999" stroke="black" stroke-width="2" stroke-linejoin="round"/></svg>';
		const arrowNextString =
			'<svg height="16" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 1L12 9L4 17" stroke="black" stroke-width="2" stroke-linejoin="round"/></svg>';

		const galleryElements = document.querySelectorAll('[data-gallery]');
		let swiperGallery = '.tax-staff a.gallery-item';
		let swiperChildren = null;
		if (galleryElements.length > 0) {
			swiperGallery = '.tax-staff';
			swiperChildren = 'a.gallery-item';
		}

		const lightbox = new PhotoSwipeLightbox({
			closeSVG: closeSvgString,
			arrowPrevSVG: arrowPrevString,
			arrowNextSVG: arrowNextString,
			gallery: swiperGallery,
			children: swiperChildren,
			pswpModule: () => import('photoswipe'),
			bgOpacity: 1,
			zoom: false,
			initialZoomLevel: 'fit',
			secondaryZoomLevel: 'fit',
			maxZoomLevel: 'fit',
		});

		new PhotoSwipeDynamicCaption(lightbox, {
			type: 'aside',
			mobileCaptionOverlapRatio: 1,
		});

		lightbox.init();
		lightbox.on('openingAnimationEnd', () => {
			disableScroll();
			window.addEventListener('wheel', closeLightbox);
		});
		lightbox.on('closingAnimationEnd', () => {
			window.removeEventListener('wheel', closeLightbox);
			enableScroll();
		});

		function closeLightbox() {
			lightbox.pswp.close();
		}

		const disableScroll = () => {
			document.documentElement.classList.add('overflow-hidden');
			document.documentElement.style.paddingRight = '15px';
		};
		const enableScroll = () => {
			document.documentElement.classList.remove('overflow-hidden');
			document.documentElement.style.paddingRight = '0px';
		};
	},
	finalize() {},
};
