// Main.js - old js file form LGC
import './js/main.js';

// Styles
import './styles/tailwind.css';
// Routing
import Router from './js/util/Router'
import common from './js/routes/common'
import home from './js/routes/home'
import pageTemplatePageDashboard from './js/routes/pageTemplatePageDashboard'
import pageTemplatePageAuthors from './js/routes/pageTemplatePageAuthors'
import postTemplate from './js/routes/postTemplate.js'
import liveUpdates from './js/routes/liveUpdates.js'
import electionTemplateDefault from './js/routes/electionTemplateDefault.js'
import pageTemplatePageElectionsObservatoryHome from './js/routes/pageTemplatePageElectionsObservatoryHome.js'
import singleVideo from './js/routes/singleVideo.js'
import Pdf from './js/util/Pdf'
import SavePost from './js/util/SavePost'
import Login from './js/util/Login'
import Newsletter from './js/util/Newsletter'
import Signup from './js/util/Signup'
import taxStaff from './js/routes/taxStaff.js'
import pageTemplatePageCardinals from './js/routes/pageTemplatePageCardinals.js';


/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  /** Home page */
  home,
  /** About Us page, note the change from about-us to aboutUs. */
  pageTemplatePageDashboard,
  pageTemplatePageAuthors,
  postTemplate,
  liveUpdates,
  pageTemplatePageElectionsObservatoryHome,
  electionTemplateDefault,
  singleVideo,
  taxStaff,
  pageTemplatePageCardinals,
})


const pdf = new Pdf();
const savePost = new SavePost();
const newsletter = new Newsletter();

const login = new Login();
const signup = new Signup();

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());
