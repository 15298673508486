const DEV = process.env.NODE_ENV === 'development';
import {
	Tabulator,
	SortModule,
	FilterModule,
	HtmlTableImportModule,
	FormatModule,
	FrozenColumnsModule,
} from 'tabulator-tables';
import '/node_modules/tabulator-tables/dist/css/tabulator.min.css';
import { DateTime } from 'luxon';

Tabulator.registerModule([
	SortModule,
	FilterModule,
	HtmlTableImportModule,
	FormatModule,
	FrozenColumnsModule,
]);

export default {
	init() {
		const loader = document.getElementById('loader'),
			selects = document.querySelectorAll('.table_select'),
			nameSearch = document.getElementById('nameSearch'),
			papabileCheckbox = document.getElementById('papabile'),
			cardinalElectorCheckbox =
				document.getElementById('cardinal_elector'),
			filtersRecap = document.getElementById('filtersRecap'),
			clearFiltersBtn = document.getElementById('clearFilters'),
			totalNumber = document.getElementById('totalNumber'),
			pills = document.getElementById('pills');

		let typingTimer,
			filters = {};

		const table = new Tabulator('#cardinalsTable', {
			dependencies: {
				DateTime: DateTime,
			},
			layout: 'fitDataFill',
			columns: [
				{
					title: 'cardinal',
					field: 'cardinal',
					formatter: 'html',
					width: 220,
					//frozen: true,
				},
				{
					title: 'nation',
					field: 'nation',
					formatter: customHtml,
					width: 110,
				},
				{
					title: 'continent',
					field: 'continent',
					formatter: customHtml,
				},
				{
					title: 'job',
					field: 'job',
					formatter: customHtml,
					width: 100,
				},
				{
					title: 'created_by',
					field: 'created_by',
					formatter: customHtml,
				},
				{
					title: 'state',
					field: 'state',
					formatter: customHtml,
				},
				{
					title: 'function',
					field: 'function',
					formatter: customHtml,
				},
				{
					title: 'age',
					field: 'age',
					formatter: customHtml,
				},
				{
					title: 'papabile',
					field: 'papabile',
					visible: false,
				},
				{
					title: 'cardinal_elector',
					field: 'cardinal_elector',
					visible: false,
				},
				{
					title: 'elector_until',
					field: 'elector_until',
					sorter: 'date',
				},
				{
					title: 'positioning',
					field: 'positioning',
					formatter: customProgress,
				},
				{
					title: 'influence',
					field: 'influence',
					formatter: customProgress,
				},
			],
		});
		table.on('dataFiltered', function (filters, rows) {
			if (totalNumber) {
				totalNumber.innerHTML = rows.length;
			}
			if (filters.length > 0) {
				filtersRecap.style.display = 'block';
			} else {
				filtersRecap.style.display = 'none';
			}
		});
		table.on('dataProcessed', function () {
			customFilter();
			loader.style.display = 'none';
		});

		selects.forEach((select) => {
			filters[select.name] = null;
			select.addEventListener('change', function (e) {
				filters[select.name] = {
					value: select.value,
					title: select.dataset.title,
				};
				customFilter();
			});
		});

		nameSearch.addEventListener('keyup', function (e) {
			clearTimeout(typingTimer);
			typingTimer = setTimeout(function () {
				filters.cardinal = {
					value: nameSearch.value,
					title: nameSearch.dataset.title,
				};
				customFilter();
			}, 500);
		});
		nameSearch.addEventListener('keydown', function () {
			clearTimeout(typingTimer);
		});

		papabileCheckbox.addEventListener('change', function () {
			if (papabileCheckbox.checked) {
				cardinalElectorCheckbox.checked = false;
				if (filters.cardinal_elector)
					filters.cardinal_elector.value = false;
			}
			filters['papabile'] = {
				value: papabileCheckbox.checked,
				title: papabileCheckbox.dataset.title,
			};
			customFilter();
		});

		cardinalElectorCheckbox.addEventListener('change', function () {
			if (cardinalElectorCheckbox.checked) {
				papabileCheckbox.checked = false;
				if (filters.papabile) filters.papabile.value = false;
			}
			filters['cardinal_elector'] = {
				value: cardinalElectorCheckbox.checked,
				title: cardinalElectorCheckbox.dataset.title,
			};
			customFilter();
		});

		clearFiltersBtn.addEventListener('click', function () {
			resetFilters();
			table.clearFilter();
		});

		pills.addEventListener('click', function (event) {
			if (event.target.classList.contains('value')) {
				let pill = event.target.closest('.filter-pill');
				if (pill) {
					let key = pill.dataset.name;
					filters[key].value = '';
					let inputs = document.querySelectorAll(
						'[name="' + key + '"]'
					);
					inputs.forEach((input) => {
						input.value = '';
					});
					pill.remove();
					customFilter();
				}
			}
		});

		function customFilter() {
			let currentFilters = [];

			pills.innerHTML = '';

			Object.keys(filters).forEach(function (key) {
				if (
					filters[key] &&
					filters[key].value != '' &&
					filters[key].value != null
				) {
					if (key == 'age') {
						let values = filters[key].value.split('-');
						let currentFilter1 = {
							field: key,
							type: '>=',
							value: Number(values[0]),
						};
						let currentFilter2 = {
							field: key,
							type: '<=',
							value: Number(values[1]),
						};

						currentFilters.push(currentFilter1, currentFilter2);
					} else {
						let currentFilter = {
							field: key,
							type: key == 'cardinal' ? 'like' : '=',
							value: filters[key].value,
						};
						currentFilters.push(currentFilter);
					}

					if (filters[key].value != true) {
						pills.innerHTML +=
							'<li class="filter-pill" data-name="' +
							key +
							'">' +
							filters[key].title +
							':<div class="value">' +
							filters[key].value +
							'</div></li>';
					}
				}
			});

			console.log(currentFilters);

			if (currentFilters.length > 0) {
				table.setFilter(currentFilters);
			} else {
				table.clearFilter();
			}
		}

		function resetFilters() {
			nameSearch.value = '';
			papabileCheckbox.checked = false;
			cardinalElectorCheckbox.checked = false;
			selects.forEach((select) => {
				select.value = '';
			});
			filters = {};
			pills.innerHTML = '';
		}

		function customProgress(cell) {
			let val = Math.floor(cell.getValue());
			let left = 10 * val + 50;
			let field = cell.getField();
			let labelInput = document.getElementById(field + 'Label');
			let label = '';
			if (labelInput) {
				label = labelInput.value;
			}
			let html = `<div class="relative w-full">
					<div class="overtitle-article text-[12px] uppercase text-center mb-2s">${label}</div>
					<div class="progress_bar relative w-full rounded-full">
						<div style="left: ${left}%" class="absolute top-1/2 -translate-y-1/2 -translate-x-1/2 w-2.5s h-2.5s rounded-full bg-cardinale"></div>
					</div>
				</div>`;
			return html;
		}

		function customHtml(cell) {
			return '<div class="cell-content">' + cell.getValue() + '</div>';
		}
	},
	finalize() {},
};
