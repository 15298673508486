const DEV = process.env.NODE_ENV === 'development'
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'
import PhotoSwipeDynamicCaption from '../libs/photoswipe-caption'
import MicroModal from 'micromodal' // es6 module

export default {
  init() {
    if (window.UsElectionData) {
      const resultsTolltip = document.getElementById('results-tooltip')
      const tooltipContainer = document.querySelector('.us-election-container')
      const currentLanguage = document.documentElement.lang
      let haveResults = false
      MicroModal.init({ disableFocus: true })
      // duplicate the json variable UsEelectionData and create a new object with all its data
      window.Temp_UsElectionData = JSON.parse(
        JSON.stringify(window.UsElectionData)
      )
      calculateUsData()
      // create a new object with all its data
      const usModal = document.getElementById('modal-US')
      document.querySelectorAll('.single-state').forEach((element) => {
        let stateData = window.Temp_UsElectionData.find(
          (state) => state.Code === element.getAttribute('data-state')
        )
        if (stateData.Res === 'true') {
          haveResults = true
          usModal.classList.add('have-results')
          element.addEventListener('mouseover', (e) => {
            element.style.cursor = 'default'
            resultsTolltip.classList.remove('hidden')
            resultsTolltip.querySelector('.state-name').textContent =
              stateData.State
            resultsTolltip.querySelector('.state-votes').textContent =
              stateData.Votes
            resultsTolltip.querySelector('.res-label').textContent =
              stateData.Label
            resultsTolltip.querySelector('.result-msg').innerHTML =
              stateData.ResData

            if (stateData.Ranking === 'solid-D') {
              resultsTolltip
                .querySelector('.final-name')
                .classList.remove('bgcolor-solid-R')
              resultsTolltip
                .querySelector('.final-name')
                .classList.add('bgcolor-solid-D')
              resultsTolltip.querySelector('.final-name').textContent = 'Harris'
            } else if (stateData.Ranking === 'solid-R') {
              resultsTolltip
                .querySelector('.final-name')
                .classList.remove('bgcolor-solid-D')
              resultsTolltip
                .querySelector('.final-name')
                .classList.add('bgcolor-solid-R')
              resultsTolltip.querySelector('.final-name').textContent = 'Trump'
            }

            const updateTooltipPosition = (event) => {
              const containerRect = tooltipContainer.getBoundingClientRect()
              if (
                event.clientX + resultsTolltip.offsetWidth >
                window.innerWidth
              ) {
                if (event.clientX - resultsTolltip.offsetWidth < 10) {
                  resultsTolltip.style.left = `${
                    event.clientX - resultsTolltip.offsetWidth / 2
                  }px`
                } else {
                  resultsTolltip.style.left = `${
                    event.clientX - resultsTolltip.offsetWidth
                  }px`
                }
              } else {
                resultsTolltip.style.left = `${
                  event.clientX - containerRect.left
                }px`
              }
              resultsTolltip.style.top = `${
                event.clientY - containerRect.top
              }px`
            }

            updateTooltipPosition(e)

            element.addEventListener('mousemove', updateTooltipPosition)

            element.addEventListener(
              'mouseout',
              () => {
                resultsTolltip.classList.add('hidden')
                element.removeEventListener('mousemove', updateTooltipPosition)
              },
              { once: true }
            )
          })
        }
      })

      document.querySelectorAll('.single-state').forEach((element) => {
        element.addEventListener('click', (e) => {
          let stateData = window.Temp_UsElectionData.find(
            (state) => state.Code === element.getAttribute('data-state')
          )
          if (stateData.Res === 'true') {
            return
          }
          window.currentUsState = stateData.Code
          usModal.querySelector('.state-votes').textContent = stateData.Votes
          usModal.querySelector('.state-polls').textContent = stateData.Polls
          if (currentLanguage.includes('es')) {
            usModal.querySelector('.state-name').textContent =
              stateData.State_ES
          } else if (currentLanguage.includes('it')) {
            usModal.querySelector('.state-name').textContent =
              stateData.State_IT
          } else {
            usModal.querySelector('.state-name').textContent = stateData.State
          }

          //usModal.querySelector('.state-rank').textContent = stateData.Ranking
          switch (stateData.Ranking) {
            case 'solid-R':
              usModal.querySelector('.state-rank').textContent =
                document.querySelector('.string-rep').textContent
              break
            case 'user-sel-D':
            case 'solid-D':
              usModal.querySelector('.state-rank').textContent =
                document.querySelector('.string-dem').textContent
              break
            case 'lean-D':
              usModal.querySelector('.state-rank').textContent =
                document.querySelector('.string-l-dem').textContent
              break
            case 'lean-R':
              usModal.querySelector('.state-rank').textContent =
                document.querySelector('.string-l-rep').textContent
              break
            case 'swing':
              usModal.querySelector('.state-rank').textContent =
                document.querySelector('.string-toss').textContent
              break
          }

          usModal.querySelectorAll('.btn-us-elections').forEach((element) => {
            element.classList.remove('active')
          })
          if (
            stateData.Ranking === 'user-sel-R' ||
            stateData.Ranking === 'solid-R'
          ) {
            usModal
              .querySelector('.btn-us-elections[data-party="R"]')
              .classList.add('active')
          } else if (
            stateData.Ranking === 'user-sel-D' ||
            stateData.Ranking === 'solid-D'
          ) {
            usModal
              .querySelector('.btn-us-elections[data-party="D"]')
              .classList.add('active')
          } else if (stateData.Ranking === 'lean-D') {
            usModal
              .querySelector('.btn-us-elections[data-party="lD"]')
              .classList.add('active')
          } else if (stateData.Ranking === 'lean-R') {
            usModal
              .querySelector('.btn-us-elections[data-party="lR"]')
              .classList.add('active')
          } else if (stateData.Ranking === 'swing') {
            usModal
              .querySelector('.btn-us-elections[data-party="S"]')
              .classList.add('active')
          }

          usModal
            .querySelector('.state-rank')
            .classList.remove(
              'solid-D',
              'lean-D',
              'user-sel-D',
              'solid-R',
              'lean-R',
              'user-sel-R'
            )
          usModal
            .querySelector('.state-rank')
            .classList.add(element.getAttribute('data-rank'))
          usModal.querySelector('.state-rank').classList.add(stateData.Ranking)
          MicroModal.show('modal-US')
        })
      })

      usModal.querySelectorAll('.btn-us-elections').forEach((element) => {
        element.addEventListener('click', (e) => {
          if (element.classList.contains('active')) {
            //console.log('click active')
            element.classList.remove('active')
            window.Temp_UsElectionData.find(
              (state) => state.Code === window.currentUsState
            ).Ranking = 'swing'
            calculateUsData()
            setTimeout(() => {
              MicroModal.close('modal-US')
            }, 200)
            return
          } else {
            //console.log('click non active')
            document
              .querySelectorAll('.btn-us-elections')
              .forEach((element) => {
                element.classList.remove('active')
              })
            element.classList.add('active')

            const selectedCandidate = element.getAttribute('data-party')
            if (selectedCandidate === 'R') {
              window.Temp_UsElectionData.find(
                (state) => state.Code === window.currentUsState
              ).Ranking = 'solid-R'
              //console.log('selected R')
            } else if (selectedCandidate === 'lR') {
              window.Temp_UsElectionData.find(
                (state) => state.Code === window.currentUsState
              ).Ranking = 'lean-R'
              console.log('selected lR')
            } else if (selectedCandidate === 'D') {
              window.Temp_UsElectionData.find(
                (state) => state.Code === window.currentUsState
              ).Ranking = 'solid-D'
              console.log('selected D')
            } else if (selectedCandidate === 'lD') {
              window.Temp_UsElectionData.find(
                (state) => state.Code === window.currentUsState
              ).Ranking = 'lean-D'
              console.log('selected lD')
            } else if (selectedCandidate === 'S') {
              window.Temp_UsElectionData.find(
                (state) => state.Code === window.currentUsState
              ).Ranking = 'swing'
            }

            calculateUsData()
            setTimeout(() => {
              MicroModal.close('modal-US')
            }, 200)
          }
        })
      })

      document
        .querySelector('.us-election-bkg')
        .addEventListener('click', (e) => {
          MicroModal.close('modal-US')
        })

      document
        .getElementById('reset-us-data')
        .addEventListener('click', (e) => {
          resetUsData()
          MicroModal.close('modal-US')
        })
    }

    const closeSvgString =
      '<svg width="24" height="24" viewBox="0 0 24 24" fill="#000000" xmlns="http://www.w3.org/2000/svg"><path d="M 23.142578 0 C 22.923216 0 22.704509 0.084585725 22.537109 0.25195312 L 14.857422 7.9296875 L 14.857422 3 C 14.857422 2.52661 14.4734 2.1425781 14 2.1425781 C 13.5266 2.1425781 13.142578 2.52661 13.142578 3 L 13.142578 9.8574219 C 13.142578 10.330772 13.5266 10.714844 14 10.714844 L 20.857422 10.714844 C 21.330822 10.714844 21.714844 10.330772 21.714844 9.8574219 C 21.714844 9.3840319 21.330822 9 20.857422 9 L 16.212891 9 L 23.748047 1.4628906 C 24.082847 1.1281606 24.082847 0.58668813 23.748047 0.25195312 C 23.580697 0.084585725 23.361941 0 23.142578 0 z M 3 13.142578 C 2.52661 13.142578 2.1425781 13.5266 2.1425781 14 C 2.1425781 14.4734 2.52661 14.857422 3 14.857422 L 7.9296875 14.857422 L 0.25195312 22.537109 C -0.082781675 22.871809 -0.082781675 23.413247 0.25195312 23.748047 C 0.58668813 24.082747 1.1281606 24.082747 1.4628906 23.748047 L 9 16.212891 L 9 20.857422 C 9 21.330722 9.3840419 21.714844 9.8574219 21.714844 C 10.330802 21.714844 10.714844 21.330722 10.714844 20.857422 L 10.714844 14 C 10.714844 13.5266 10.330802 13.142578 9.8574219 13.142578 L 3 13.142578 z " /></svg>'

    const arrowPrevString =
      '<svg height="16" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 17L4 9L12 0.999999" stroke="black" stroke-width="2" stroke-linejoin="round"/></svg>'
    const arrowNextString =
      '<svg height="16" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 1L12 9L4 17" stroke="black" stroke-width="2" stroke-linejoin="round"/></svg>'

    const galleryElements = document.querySelectorAll('[data-gallery]')
    let swiperGallery = '.post-template a.gallery-item'
    let swiperChildren = null
    if (galleryElements.length > 0) {
      swiperGallery = '.post-template'
      swiperChildren = 'a.gallery-item'
    }

    const lightbox = new PhotoSwipeLightbox({
      closeSVG: closeSvgString,
      arrowPrevSVG: arrowPrevString,
      arrowNextSVG: arrowNextString,
      gallery: swiperGallery,
      children: swiperChildren,
      pswpModule: () => import('photoswipe'),
      bgOpacity: 1,
      zoom: false,
      initialZoomLevel: 'fit',
      secondaryZoomLevel: 'fit',
      maxZoomLevel: 'fit',
    })

    new PhotoSwipeDynamicCaption(lightbox, {
      type: 'aside',
      mobileCaptionOverlapRatio: 1,
    })

    lightbox.init()
    lightbox.on('openingAnimationEnd', () => {
      disableScroll()
      window.addEventListener('wheel', closeLightbox)
    })
    lightbox.on('closingAnimationEnd', () => {
      window.removeEventListener('wheel', closeLightbox)
      enableScroll()
    })

    function closeLightbox() {
      lightbox.pswp.close()
    }

    const disableScroll = () => {
      document.documentElement.classList.add('overflow-hidden')
      document.documentElement.style.paddingRight = '15px'
    }
    const enableScroll = () => {
      document.documentElement.classList.remove('overflow-hidden')
      document.documentElement.style.paddingRight = '0px'
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    //console.log('finalize common');
  },
}

function resetUsData() {
  window.Temp_UsElectionData = {}
  window.Temp_UsElectionData = JSON.parse(JSON.stringify(window.UsElectionData))
  // console.log('resetUsData', window.Temp_UsElectionData)
  // console.log('UsElectionData', window.UsElectionData)

  calculateUsData()
}
function calculateUsData() {
  let solidD = window.Temp_UsElectionData.filter(
    (state) => state.Ranking === 'solid-D'
  ).reduce((sum, state) => sum + state.Votes, 0)
  let leanD = window.Temp_UsElectionData.filter(
    (state) => state.Ranking === 'lean-D'
  ).reduce((sum, state) => sum + state.Votes, 0)
  let userD = window.Temp_UsElectionData.filter(
    (state) => state.Ranking === 'user-sel-D'
  ).reduce((sum, state) => sum + state.Votes, 0)
  let solidR = window.Temp_UsElectionData.filter(
    (state) => state.Ranking === 'solid-R'
  ).reduce((sum, state) => sum + state.Votes, 0)
  let leanR = window.Temp_UsElectionData.filter(
    (state) => state.Ranking === 'lean-R'
  ).reduce((sum, state) => sum + state.Votes, 0)
  let userR = window.Temp_UsElectionData.filter(
    (state) => state.Ranking === 'user-sel-R'
  ).reduce((sum, state) => sum + state.Votes, 0)

  document.getElementById('total-votes-D').textContent =
    parseInt(solidD) + parseInt(leanD) + parseInt(userD)
  document.getElementById('total-votes-R').textContent =
    parseInt(solidR) + parseInt(leanR) + parseInt(userR)

  // console.log('solidD', solidD)
  // console.log('leanD', leanD)
  // console.log('userD', userD)
  // console.log('solidR', solidR)
  // console.log('leanR', leanR)
  // console.log('userR', userR)
  document.documentElement.style.setProperty('--solid-D', solidD)
  document.documentElement.style.setProperty('--lean-D', leanD)
  document.documentElement.style.setProperty('--user-sel-D', userD)
  document.documentElement.style.setProperty('--solid-R', solidR)
  document.documentElement.style.setProperty('--lean-R', leanR)
  document.documentElement.style.setProperty('--user-sel-R', userR)
  document.querySelectorAll('.single-state').forEach((element) => {
    const stateCode = element.getAttribute('data-state')
    const stateData = window.Temp_UsElectionData.find(
      (state) => state.Code === stateCode
    )
    element.classList.remove(
      'fill-solid-D',
      'fill-solid-R',
      'fill-lean-D',
      'fill-lean-R',
      'fill-user-sel-D',
      'fill-user-sel-R',
      'fill-swing'
    )
    if (stateData) {
      element.classList.add(
        `fill-${
          stateData.Ranking.charAt(0).toLowerCase() + stateData.Ranking.slice(1)
        }`
      )
    }
  })
}
